<template>
    <div>
        <!-- 套卷列表 -->
        <subjective-paper-list :is-all="false" />

        <!-- 主观题专栏课 -->
        <div style="margin-top: 50px;">
            <!-- 标题栏 -->
            <el-row type="flex" align="middle">
                <el-col :xs="4" :sm="4" :md="3" :lg="2" :xl="1">
                    <el-image style="width: 33px; height: 33px" :src="require('../../assets/icon/pc-index1.png')"/>
                </el-col>
                <el-col :xs="20" :sm="20" :md="21" :lg="22" :xl="23">
                    <div style="font-size: 20px;">
                        主观题专栏推荐
                    </div>
                </el-col>
            </el-row>

            <!-- 专栏课列表展示区-->
            <topic-list style="margin-top: 20px; margin-left: -15px;" />
        </div>
    </div>
</template>

<script>
import TopicList from "../topic/components/topicList";
import SubjectivePaperList from "@/views/my/components/subjectivePaperList";

export default {
    name: "index",
    components: {SubjectivePaperList, TopicList},
    data() {
        return {}
    },
    mounted() {

    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#f5f6fa')
    }
}
</script>