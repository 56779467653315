<template>
    <div>
        <!-- 套卷列表-->
        <el-row :gutter="20">
            <el-col :span="8" v-for="(item, key) in listData" :key="key">
                <page-main class="paper">
                    <!--套卷标签-->
                    <div class="paperTag">
                        <el-tag size="mini">套卷</el-tag>
                        <el-tag v-if="item.bdSubjectivePaper.isTeacherCheck" type="warning" size="mini">名师点评</el-tag>
                    </div>

                    <!--套卷名称-->
                    <div class="paperTitle">
                        {{ item.bdSubjectivePaper.name }}
                    </div>

                    <!--套卷信息-->
                    <div class="paperInfo">
                        <div>
                            试卷总分：<span style="font-weight: bold">{{ item.bdSubjectivePaper.totalScore }}分</span>
                        </div>
                        <div style="margin-top: 10px;">
                            考试时间：<span style="font-weight: bold">{{ item.bdSubjectivePaper.totalTime }}分钟</span>
                        </div>
                    </div>
                    <!-- 操作栏 -->
                    <el-row style="text-align: center; margin-top: 25px; margin-bottom: 20px;">
                        <el-col v-if="item.bdSubjectivePaper.isComplete" :span="12">
                            <el-button v-loading="isCorrectLoading" type="success" style="width: 80%;" @click="handleOpenCorrectRecord(item)">
                                查看批改
                            </el-button>
                        </el-col>
                        <el-col v-if="item.bdSubjectivePaper.isComplete" :span="12">
                            <el-button :type="item.bdSubjectivePaper.isComplete ? 'success' : 'primary'"
                                       style="width: 80%;"
                                       v-loading="btnLoading"
                                       @click="handleShowAnswer(item.bdSubjectivePaper.id, item.sourceId)">
                                查看答案
                            </el-button>
                        </el-col>
                        <el-col v-if="!item.bdSubjectivePaper.isComplete" :span="24">
                            <el-button type="primary"
                                       @click="handleToPaper(item, item.bdSubjectivePaper.isComplete)"
                                       style="width: 200px;">
                                开始答题
                            </el-button>
                        </el-col>
                    </el-row>
                </page-main>
            </el-col>
        </el-row>

        <!-- 无套卷提示 -->
        <div v-if="listData.length === 0"
             style="text-align: center; margin: 30px;"
             v-loading="loading"
             element-loading-background="transparent">
            <h3 v-if="!loading">当前暂无套卷~</h3>
        </div>

        <!-- 二维码 -->
        <el-dialog title="查看批改" :visible.sync="showCode" :modal-append-to-body="false" @close="showTool = false"
                   width="75%">
            <div>
                <div style="text-align: center; margin-top: -20px;">
                    <h4>本页面提供基础的批改内容展示<br>如果您需要更全面的统计信息，可以进入【清单法考】小程序，在“我的”-“清单套卷”里<br>查看【专属阅卷报告】</h4>
                </div>
                <!--<div style="text-align: center">-->
                <!--    <vue-qrcode :value="codeUrl" :options="{ width: 150 }"></vue-qrcode>-->
                <!--</div>-->
            </div>
            <div v-if="paperData.isTeacherCheck" style="margin-bottom: 20px;">
                <el-card>
                    <div slot="header">
                        <div style="display: flex; align-items: center;">
                            <el-image
                                style="width: 40px; height: 40px"
                                :src="require('@/assets/icon/check-2.svg')"
                                :fit="fit">
                            </el-image>
                            <div style="font-size: 16px;">
                                名师点评
                            </div>
                        </div>
                    </div>
                    <div style="line-height: 1.5; font-size: 16px;">
                        {{ paperData.checkContent }}
                    </div>
                </el-card>
            </div>

            <!-- 套卷批改内容 -->
            <div v-for="(subjective, key1) in studentData" :key="key1">
                <div style="font-size: 20px;">
                    【{{ '第' + (key1 + 1) + '题：' + subjective.bdSubjective.name }}】
                </div>
                <div v-for="(subjectiveQuestionRecord, key2) in subjective.subjectiveQuestionRecordList" :key="key2">
                    <el-card :header="'第' + (key2 + 1) + '问（' + subjectiveQuestionRecord.bdSubjectiveQuestionRecord.studentScore + ' / ' + subjectiveQuestionRecord.bdSubjectiveQuestionRecord.questionScore + ' 分）'" style="margin-top: 20px; margin-bottom: 20px;">
                        <div class="answer">
                            <div v-for="(mark, key3) in subjectiveQuestionRecord.bdSubjectiveQuestionMarkRecordList"
                                 :key="mark.id" style="display: inline;" :class="chooseAnswerStyle(mark)">
                                <div v-if="mark.markComment" style="display: inline;">
                                    <el-tooltip :value="showTool" effect="dark" placement="bottom-end"
                                                style="display: inline; background-color: transparent;">
                                        <div slot="content" style="line-height: 1.5;">
                                            【得分：{{ mark.markScore }}分】<br/>
                                            【采分点：{{ mark.markPoint }}】<br/>
                                            【评语：{{ mark.markComment }}】
                                        </div>
                                        <div style="display: inline; cursor: pointer;" @click="showTool = !showTool">
                                            {{ mark.studentAnswerSentence }}
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div v-else style="display: inline;">
                                    {{ mark.studentAnswerSentence }}
                                </div>
                                <div style="display: inline;">
                                    {{
                                        markEnd(mark, key3, subjectiveQuestionRecord.bdSubjectiveQuestionMarkRecordList.length)
                                    }}
                                </div>
                            </div>

                            <el-divider>遗漏的采分点</el-divider>
                            <div style="color: red; display: flex;">
                                <div v-for="(item, key) in subjectiveQuestionRecord.bdSubjectiveQuestionMarkRecordList" :key="key">
                                    <span v-if="!item.studentAnswerSentence">
                                    【{{ item.markPoint }}】
                                    </span>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </el-dialog>

        <!-- 答案解析 -->
        <el-dialog title="答案与解析" :visible.sync="showAnswer" :modal-append-to-body="false" width="80%">
            <div v-for="(subjective, index1) in studentAnswerData" :key="index1">
                <div style="font-size: 20px; font-weight: bold; margin: 20px 0;">
                    【第{{ index1 + 1 }}题】
                </div>
                <div style="margin-top: 20px;">
                    <el-card header="案情内容"
                             :body-style="{height: '180px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                        {{ subjective.bdSubjective.caseDetail }}
                    </el-card>
                </div>
                <div v-for="(currentData, key) in subjective.bdSubjectiveQuestionList" :key="key">
                    <div style="font-size: 18px; margin-top: 30px; margin-bottom: 20px; margin-left: 10px;">
                        第{{ key + 1 }}问：{{ currentData.questionStem }}（{{ currentData.questionScore }}分）
                    </div>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-card header="你的答案" :body-style="{height: '200px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                                {{ subjective.subjectiveQuestionRecordList[key].bdSubjectiveQuestionRecord.studentAnswer }}
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card header="参考答案"
                                     :body-style="{height: '200px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                                {{ currentData.suggestAnswer }}
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

// import VueQrcode from "@chenfengyuan/vue-qrcode";
import {Message} from "element-ui";

export default {
    name: "subjectivePaperList",
    // components: {VueQrcode},
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        },
        limitTime: {
            type: Boolean,
            default() {
                return false
            }
        },
        startTime: {
            type: String,
            default: ''
        },
        endTime: {
            type: String,
            default: ''
        },
        isAll: {
            type: Boolean,
            default() {
                return false
            }
        },
        isData: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    watch: {
        dataList(val) {
            this.listData = val
        }
    },
    data() {
        return {
            showCode: false,
            codeUrl: '',
            showAnswer: false,
            studentAnswerData: [],
            studentData: [],
            showTool: false,
            paperData: {},
            loading: false,
            listData: [],
            btnLoading: false,
            isCorrectLoading: false
        }
    },
    mounted() {
        // 直接接口获取，获取学生要做的套卷
        if (!this.isData) {
            this.loading = true
            this.$api.get('/pc/subjective/getStudentPCSubjectivePaper', {
                params: {
                    isAll: this.isAll
                }
            }).then(res => {
                this.loading = false
                this.listData = res.data
            }).catch(err => {
                console.log(err)
                this.loading = false
            })
        }
    },
    methods: {
        // 生成答案样式
        chooseAnswerStyle(mark) {
            if (mark.markComment) {
                return mark.markScore ? 'answerRight' : 'answerWrong'
            } else {
                return ''
            }
        },
        // 生成标点符号
        markEnd(mark, key, totalLength) {
            if (mark.studentAnswerSentence && mark.studentAnswerSentence.substr(mark.studentAnswerSentence.length - 1, 1) !== '，' && mark.studentAnswerSentence.substr(mark.studentAnswerSentence.length - 1, 1) !== '。') {
                if (key + 1 === totalLength) {
                    return '。'
                } else {
                    return '，'
                }
            }
            return ''
        },
        // 查看批改记录
        handleOpenCorrectRecord(item) {
            let _this = this
            // 先查套卷状态
            this.$api.get('/subjectivePaper/getSubjectivePaper', {
                params: {
                    paperId: item.bdSubjectivePaper.id,
                    sourceId: item.sourceId
                }
            }).then(res => {
                if (res.data.isComplete) {
                    if (res.data.status !== 2) {
                        // 正在批改中
                        Message.warning('试卷正在批改中……批改后公众号会发送通知！')
                    } else {
                        // 批改完毕，获取批改数据
                        this.codeUrl = "http://member.harchinaedu.com/#/pages/subjective/subjectiveTips?id=" + item.id + "&type=0"
                        _this.handleGetCorrectData(res.data.data.id)
                    }
                } else {
                    Message.warning('套卷尚未完成！')
                }
            })
        },
        // 获取批改结果
        handleGetCorrectData(id) {
            this.isCorrectLoading = true
            this.$api.get('/subjectivePaper/getStudentPaperStatistic', {
                params: {
                    recordId: id
                }
            }).then(res => {
                this.isCorrectLoading = false
                this.showCode = true
                res = res.data

                this.studentData = res.subjectiveStatisticList
                this.paperData = res.bdSubjectivePaper
            }).catch(err => {
                this.isCorrectLoading = false
                console.log(err)
                Message.error('获取套卷批改错误！')
            })
        },
        // 查看套卷记录答案
        handleShowAnswer(id, sourceId) {
            this.btnLoading = true
            this.$api.get('/pc/subjective/getSubjectivePaperAnswer', {
                params: {
                    id: id,
                    sourceId: sourceId
                }
            }).then(res => {
                this.studentAnswerData = res.data
                this.showAnswer = true
                this.btnLoading = false
            }).catch(err => {
                console.log(err)
                this.btnLoading = false
                this.$message.error(err.msg || '获取数据失败')
            })
        },
        // 开始答题
        handleToPaper(item, isComplete) {
            let flag = 0
            if (!isComplete) {
                if (this.limitTime && this.startTime && this.endTime) {
                    // 指定了时间
                    var date1 = new Date();
                    let startTime = new Date(this.startTime)
                    let endTime = new Date(this.endTime)
                    if (date1 < startTime) {
                        // 尚未开始
                        flag = 1
                    } else if (date1 > endTime) {
                        // 已结束
                        flag = 2
                    }
                }

                if (flag === 0) {
                    this.$router.push({name: 'subjectivePaperChoose', query: {id: item.bdSubjectivePaper.id, sourceId: item.sourceId, sourceType: item.sourceType}})
                } else if (flag === 1) {
                    Message.warning("专栏课尚未开放，请耐心等待~")
                } else if (flag === 2) {
                    Message.warning("开放时间已截止，请关注后续活动通知~")
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.answer {
    font-size: 16px;
    line-height: 2.5;
    white-space: pre-wrap;
}

.paper {
    .paperTag {
        margin: 15px;
    }

    .paperTitle {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .paperInfo {
        text-align: left;
        margin: 25px 0 25px 60px;
        letter-spacing: 3px;
        font-size: 15px;
        color: #999;
    }
}

.answerWrong {
    border-bottom: 3px double #fa3534 !important;
    display: inline;
}

.answerRight {
    border-bottom: 3px double #19be6b !important;
    display: inline;
}
</style>