<template>
    <div>
        <!--    专栏课列表-->
        <div style="padding-left: 10px;">
            <el-row :gutter="20">
                <el-col :span="6" v-for="(topic, key) in topicList" :key="key">
                    <router-link :to="{name: 'topicDetail', query: {id: topic.id}}"
                                 style="text-decoration: none;">
                        <el-card :body-style="{ padding: '0px' }" class="topicCard">
                            <el-image class="img" fit="fill" :src="topic.imgUrl"/>

                            <div style="padding: 14px;">
                                <span>{{ topic.title }}</span>
                                <el-row style="margin-top: 20px;">
                                    <el-col :span="6">
                                        <el-tag type="info" size="small">专栏</el-tag>
                                    </el-col>
                                    <el-col :span="18">
                                        <div style="text-align: right;">
                                            共{{ topic.packageNum }}期
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-card>
                    </router-link>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>

export default {
    name: "topicList",
    data() {
        return {
            topicList: []
        }
    },
    mounted() {
        // 获取主观题专栏课列表
        this.$api.get('/topic/getPcTopic').then(res => {
            this.topicList = res.data
        })
    }
}
</script>

<style scoped lang="scss">
.topicCard {
    height: 281px;
    border-radius: 8px;
    margin-bottom: 30px;
    border: 0;

    &:hover {
        transition: all 0.2s;
        transform: translate3d(0, -7px, 0);
    }

    .img {
        width: 100%;
        height: 175px;
        object-fit: cover;
        overflow: hidden;
        margin: 0;
    }
}
</style>